.pageNotFound-container {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.pageNotFound-container h1 {
  font-size: 6rem;
  color: #707070;
  margin-bottom: -15px;
}

.pageNotFound-container .main-text {
  font-size: 1.5rem;
  color: #707070;
  margin-bottom: 15px;
}

.pageNotFound-container .sub-text {
  font-size: 1rem;
  color: #707070;
}

.pageNotFound-container img {
  width: 300px;
}
