* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  /* font-family: 'Montserrat'; */
  font-family: "Exo 2", "Helvetica";
  color: #313131;
}

body {
  /* background: url(background-tile-logo-bw.png) repeat; */
  background: url(logo-pattern.png) #f3f3f3 repeat;
  background-position: top left;
}
